import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles;
const { Title, Paragraph } = Typography;

const VulnerabilityManagement = () => {
    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Vulnerability Management</Title>
                <Paragraph className="hero-subtitle">Unyielding Protection for Your Digital Assets</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Paragraph>
                            In the rapidly evolving digital world, protecting your systems from vulnerabilities is crucial. At [Your Company Name], we offer state-of-the-art vulnerability management services, ensuring your digital assets are secure and resilient against potential threats. Our expert team and advanced technology provide a thorough and proactive approach to vulnerability management, offering you unparalleled security.
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Why Choose Our Vulnerability Management Services?</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Proactive Identification</Title>
                                    <Paragraph>We continuously scan and identify vulnerabilities in your systems, preventing potential threats before they can exploit weaknesses.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div> Rapid Remediation</Title>
                                    <Paragraph>Our experts quickly address and resolve identified vulnerabilities, minimizing exposure and enhancing security.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div> Cutting-Edge Technology</Title>
                                    <Paragraph>Utilizing the latest in AI and machine learning, our solutions accurately detect vulnerabilities and prioritize them based on risk.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div> Comprehensive Reports</Title>
                                    <Paragraph>Receive detailed reports with actionable insights, helping you understand and mitigate risks effectively.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Key Features of Our Services</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Continuous Scanning</Title>
                                    <Paragraph>Our advanced scanning tools continuously monitor your systems for vulnerabilities, providing real-time detection and alerts. Stay ahead of potential threats with our proactive approach.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Expert Analysis</Title>
                                    <Paragraph>Our team of seasoned security professionals analyzes identified vulnerabilities, offering expert recommendations and ensuring swift remediation. Trust in our expertise to keep your digital assets safe.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Integrated Threat Intelligence</Title>
                                    <Paragraph>We leverage integrated threat intelligence to enhance vulnerability detection and response. Our systems stay updated with the latest threat information, ensuring comprehensive protection.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Detailed Reporting</Title>
                                    <Paragraph>Gain in-depth insights with our detailed reports, highlighting vulnerabilities, risk levels, and remediation steps. Empower your team with the information needed to safeguard your systems.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Peace of Mind</Title>
                                    <Paragraph>Rest easy knowing your digital assets are under constant watch, allowing you to focus on your core business.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Cost-Effective</Title>
                                    <Paragraph>Prevent potential losses and downtime with our proactive security measures, saving you money in the long run.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Safety</Title>
                                    <Paragraph>Our rapid response and advanced technology significantly enhance the security of your digital environment.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Support</Title>
                                    <Paragraph>Our customer service team is available 24/7 to assist you with any queries or issues, ensuring a seamless security experience.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default VulnerabilityManagement;
