import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles;
const { Title, Paragraph } = Typography;

const DataAIPage = () => {
    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Data Analytics & AI Solutions</Title>
                <Paragraph className="hero-subtitle">Harnessing Data for Intelligent Insights</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Paragraph>
                            In today's data-driven world, leveraging data analytics and artificial intelligence (AI) is crucial for gaining actionable insights and driving business growth. At [Your Company Name], we specialize in delivering advanced data analytics and AI solutions tailored to meet your business objectives. Our expertise empowers you to make informed decisions, optimize operations, and stay ahead of the competition.
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Why Choose Our Data Analytics & AI Solutions?</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Data-driven Insights</Title>
                                    <Paragraph>Unlock valuable insights from your data to drive strategic decision-making and business growth.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Advanced Machine Learning</Title>
                                    <Paragraph>Utilize cutting-edge machine learning algorithms to automate processes and predict outcomes with accuracy.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Custom AI Solutions</Title>
                                    <Paragraph>Develop tailored AI solutions to address specific business challenges and enhance operational efficiency.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Real-time Analytics</Title>
                                    <Paragraph>Implement real-time analytics to monitor performance metrics and respond swiftly to changing market conditions.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Key Features of Our Data Analytics & AI Solutions</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Predictive Analytics</Title>
                                    <Paragraph>Harness predictive analytics to forecast trends, optimize resource allocation, and improve decision-making processes.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Data Visualization</Title>
                                    <Paragraph>Create interactive visualizations to communicate insights effectively and facilitate data-driven discussions.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>AI-driven Automation</Title>
                                    <Paragraph>Implement AI-driven automation to streamline workflows, reduce operational costs, and enhance productivity.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Scalable Infrastructure</Title>
                                    <Paragraph>Build scalable data infrastructure to support growing data volumes and ensure reliable performance of analytics solutions.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Improved Decision-Making</Title>
                                    <Paragraph>Gain actionable insights to make informed decisions and drive business growth effectively.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Operational Efficiency</Title>
                                    <Paragraph>Automate repetitive tasks and optimize workflows to improve operational efficiency and reduce costs.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Innovative Solutions</Title>
                                    <Paragraph>Drive innovation with custom AI solutions tailored to address specific business challenges and opportunities.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Scalability</Title>
                                    <Paragraph>Scale your data analytics capabilities to accommodate growing data volumes and business requirements effectively.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default DataAIPage;
