import React from 'react';
import { Row, Col, Card, Typography, Form, Input, Button } from 'antd';
import './ServiceStyle.css'; // Import styles for service pages
import { RightCircleTwoTone } from '@ant-design/icons';
import './pagestyle.css'; // Import homepage styles;

const { Title, Paragraph } = Typography;
const { TextArea } = Input;

const ContactUs = () => {
    const handleSubmit = (values) => {
        console.log('Submitted values:', values);
        // You can implement your logic for handling form submission here
    };

    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Contact Us</Title>
                <Paragraph className="hero-subtitle">We'd love to hear from you! Get in touch with us.</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Paragraph>
                            At [Your Company Name], we value your feedback and inquiries. Whether you have a question about our services, need support, or just want to learn more about what we do, our team is here to assist you. Please fill out the form below, and we will get back to you as soon as possible.
                        </Paragraph>
                    </Col>
                </Row>
               
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={12}>
                        <Card bordered={false}>
                            <Form layout="vertical" onFinish={handleSubmit}>
                                <Row gutter={[16, 16]}>
                                    <Col span={12}>
                                        <Form.Item label="Name" name="name" rules={[{ required: true, message: 'Please enter your name' }]}>
                                            <Input placeholder="Your Name" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Email" name="email" rules={[{ required: true, message: 'Please enter your email' }]}>
                                            <Input placeholder="Your Email" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Phone" name="phone">
                                            <Input placeholder="Your Phone Number" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={12}>
                                        <Form.Item label="Subject" name="subject">
                                            <Input placeholder="Subject" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Form.Item label="Message" name="message" rules={[{ required: true, message: 'Please enter your message' }]}>
                                            <TextArea rows={4} placeholder="Your Message" />
                                        </Form.Item>
                                    </Col>
                                    <Col span={24}>
                                        <Button type="primary" htmlType="submit" icon={<RightCircleTwoTone />}>
                                            Send Message
                                        </Button>
                                    </Col>
                                </Row>
                            </Form>
                        </Card>
                    </Col>
                    <Col span={12}>
                        <Title level={2}>Our Contact Details</Title>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Office Address</Title>
                                    <Paragraph>[Your Company Address]</Paragraph>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Phone</Title>
                                    <Paragraph>[Your Company Phone Number]</Paragraph>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Email</Title>
                                    <Paragraph>[Your Company Email Address]</Paragraph>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Working Hours</Title>
                                    <Paragraph>Monday - Friday: 9:00 AM - 6:00 PM</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ContactUs;
