import React from 'react';
import { Link } from 'react-router-dom';
import { Card as AntdCard, Button } from 'antd';

const { Meta } = AntdCard;

const Card = ({ title, description, img, link }) => {
    return (
        <AntdCard
            hoverable
            cover={<img alt={title} src={img} />}
        >
            <Meta title={title} description={description} />
            <Link to={link}>
                <Button type="primary" style={{ marginTop: '10px' }}>Know More</Button>
            </Link>
        </AntdCard>
    );
};

export default Card;
