import React from 'react';
import { Row, Col, Card, Typography, Button } from 'antd';
import './ServiceStyle.css'; // Import styles for service pages
import { RightCircleTwoTone } from '@ant-design/icons';
import './pagestyle.css'; // Import homepage styles;
const { Title, Paragraph } = Typography;

const SecurityMonitoring = () => {
    return (
        <div className="service-page">
            <div className="hero-section" >
                <Title level={1} className="hero-title">24/7 Security Monitoring: Your Digital Shield, Anytime, Anywhere</Title>
                <Paragraph className="hero-subtitle">Unmatched Security at Your Fingertips</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]}  className="even-row">
                    <Col span={24}>
                        <Paragraph>
                            In an increasingly digital world, the need for constant security has never been greater. At [Your Company Name], we provide comprehensive 24/7 security monitoring services for websites and mobile apps, ensuring your digital assets are protected around the clock. Our advanced technology and dedicated team offer robust surveillance solutions that give you peace of mind.
                        </Paragraph>
                    </Col>
                    </Row> 
                    <Row gutter={[32, 32]}>   
                    <Col span={24}>
                        <Title level={2}>Why Choose Our 24/7 Security Monitoring?</Title>
                        <ul className="feature-list">
                            <li><RightCircleTwoTone /> <strong>Constant Vigilance:</strong> Our monitoring systems work tirelessly to detect and respond to potential threats in real-time, 24/7.</li>
                            <li><RightCircleTwoTone /> <strong>Immediate Response:</strong> Our security experts are on standby to address any security breaches instantly, minimizing risks and damage.</li>
                            <li><RightCircleTwoTone /> <strong>Advanced Technology:</strong> We leverage cutting-edge technologies, including AI and machine learning, to enhance threat detection and reduce false positives.</li>
                            <li><RightCircleTwoTone /> <strong>Remote Access:</strong> Manage and monitor your security from anywhere through our intuitive mobile app and web portal.</li>
                            <li><RightCircleTwoTone /> <strong>Tailored Solutions:</strong> We offer customized security plans to meet the unique needs of your website or mobile app, ensuring comprehensive protection.</li>
                        </ul>
                    </Col>
                    </Row>
                    <Row gutter={[32, 32]}  className="even-row">
                    <Col span={24}>
                        <Title level={2}>Key Features of Our 24/7 Security Monitoring</Title>
                        <Row gutter={[16, 16]}>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Real-Time Alerts</Title>
                                    <Paragraph>Stay informed with instant notifications about any suspicious activities. Our system ensures you are always updated, whether you're at the office or on the go.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Expert Surveillance Team</Title>
                                    <Paragraph>Our team of security professionals monitors your digital assets around the clock. With years of experience, they can quickly identify and respond to potential threats, ensuring the highest level of protection.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Integrated Systems</Title>
                                    <Paragraph>We provide an integrated security solution that combines threat detection, vulnerability management, and access control. This multi-layered approach ensures robust protection for your website and mobile app.</Paragraph>
                                </Card>
                            </Col>
                            <Col span={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Seamless Integration</Title>
                                    <Paragraph>Our monitoring systems seamlessly integrate with your existing digital infrastructure, providing a cohesive security experience without disrupting your operations.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    </Row>
                    <Row gutter={[32, 32]}  >
                    <Col span={24}>
                        <Title level={2}>Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                        <Col span={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title"  level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Peace of Mind</Title>
                                    <Paragraph>Rest easy knowing your digital assets are under constant watch, allowing you to focus on your core business.</Paragraph>
                                </Card>
                            </Col>
                        <Col span={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Cost-Effective</Title>
                                    <Paragraph> Prevent potential losses and downtime with our proactive security measures, saving you money in the long run.</Paragraph>
                                </Card>
                            </Col>
                        <Col span={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Safety</Title>
                                    <Paragraph>Our rapid response and advanced technology significantly enhance the security of your digital environment.   </Paragraph>
                                </Card>
                            </Col>
                        <Col span={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Support:</Title>
                                    <Paragraph>Our customer service team is available 24/7 to assist you with any queries or issues, ensuring a seamless security experience.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                       
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default SecurityMonitoring;
