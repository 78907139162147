import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles;
const { Title, Paragraph } = Typography;

const ThreatDetectionandResponse = () => {
    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Threat Detection and Response</Title>
                <Paragraph className="hero-subtitle">Stay Vigilant with Rapid Threat Detection</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Paragraph>
                            Threat detection and response is critical in today's cybersecurity landscape. At [Your Company Name], we offer advanced threat detection services to identify and mitigate potential threats swiftly, minimizing risks to your organization.
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Why Choose Our Threat Detection and Response Services?</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Real-Time Monitoring</Title>
                                    <Paragraph>Continuous monitoring of your network and systems for real-time threat detection.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Threat Intelligence</Title>
                                    <Paragraph>Utilize threat intelligence to identify emerging threats and vulnerabilities proactively.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div> Incident Response</Title>
                                    <Paragraph>Rapid incident response capabilities to minimize the impact of security breaches and cyber incidents.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Customized Solutions</Title>
                                    <Paragraph>Tailored threat detection and response strategies to fit your organization's specific needs.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Key Features of Our Services</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Advanced Threat Detection</Title>
                                    <Paragraph>Utilize advanced technologies and methodologies to detect and analyze threats effectively.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Incident Response Planning</Title>
                                    <Paragraph>Develop and implement incident response plans to mitigate risks and respond swiftly to cyber incidents.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Continuous Monitoring</Title>
                                    <Paragraph>24/7 monitoring of your IT infrastructure to detect and respond to threats in real-time.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Threat Hunting</Title>
                                    <Paragraph>Proactive threat hunting to identify hidden threats and vulnerabilities within your systems.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Benefits of Choosing Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Security Posture</Title>
                                    <Paragraph>Enhance your overall security posture with proactive threat detection and response strategies.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Reduced Response Times</Title>
                                    <Paragraph>Minimize response times to security incidents, reducing potential damages and downtime.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Compliance Assurance</Title>
                                    <Paragraph>Ensure compliance with industry regulations and standards through effective threat detection and response.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Guidance</Title>
                                    <Paragraph>Receive expert guidance and recommendations to improve your organization's security posture.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ThreatDetectionandResponse;
