import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles;
const { Title, Paragraph } = Typography;

const RiskAnalysis = () => {
    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Risk Analysis</Title>
                <Paragraph className="hero-subtitle">Comprehensive Assessment for Informed Decision-Making</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Paragraph>
                            Effective risk analysis is crucial for organizations to identify and mitigate potential threats and vulnerabilities. At [Your Company Name], we offer comprehensive risk analysis services tailored to your business needs, providing valuable insights for informed decision-making and risk management strategies.
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Why Choose Our Risk Analysis Services?</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Comprehensive Assessment</Title>
                                    <Paragraph>Thorough assessment of risks and vulnerabilities specific to your industry and business environment.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Customized Solutions</Title>
                                    <Paragraph>Tailored risk analysis solutions to fit the unique needs and challenges of your organization.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Analysis</Title>
                                    <Paragraph>Expert analysis and recommendations to prioritize risks and implement effective mitigation strategies.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Continuous Monitoring</Title>
                                    <Paragraph>Ongoing monitoring and assessment to adapt to evolving threats and changes in your business environment.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Key Features of Our Services</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Risk Identification</Title>
                                    <Paragraph>Identify and classify risks based on their potential impact and likelihood of occurrence.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Risk Assessment</Title>
                                    <Paragraph>Assess risks using industry-standard methodologies to prioritize and manage vulnerabilities.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Risk Mitigation</Title>
                                    <Paragraph>Develop and implement mitigation strategies to reduce the impact of identified risks on your organization.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Reporting and Recommendations</Title>
                                    <Paragraph>Provide detailed reports and actionable recommendations to support decision-making and risk management efforts.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Informed Decision-Making</Title>
                                    <Paragraph>Make informed decisions based on comprehensive risk analysis and insights.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Cost-Effective Solutions</Title>
                                    <Paragraph>Implement cost-effective risk management solutions tailored to your organization's budget and resources.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Security Measures</Title>
                                    <Paragraph>Enhance your organization's security posture with proactive risk analysis and mitigation strategies.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Regulatory Compliance</Title>
                                    <Paragraph>Ensure compliance with industry regulations and standards through effective risk management practices.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default RiskAnalysis;
