import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles;
const { Title, Paragraph } = Typography;

const NetworkSecurityPage = () => {
    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Network Security Solutions</Title>
                <Paragraph className="hero-subtitle">Protecting Your Network Infrastructure with Robust Security Measures</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Paragraph>
                            Ensuring the security of your network infrastructure is critical in today's interconnected world. At [Your Company Name], we offer comprehensive network security solutions designed to safeguard your data, applications, and communications. Our tailored approach combines advanced technologies and expert strategies to mitigate risks and enhance your network's resilience.
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Why Choose Our Network Security Solutions?</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Comprehensive Protection</Title>
                                    <Paragraph>Robust security measures to protect your network from various cyber threats and attacks.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Scalable Solutions</Title>
                                    <Paragraph>Flexible solutions that scale with your business needs and network growth.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Advanced Technologies</Title>
                                    <Paragraph>Utilization of cutting-edge technologies to detect, prevent, and respond to threats effectively.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Guidance</Title>
                                    <Paragraph>Expert advice and strategies to optimize your network security posture and operations.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Key Features of Our Network Security Solutions</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Firewall and Intrusion Detection Systems (IDS)</Title>
                                    <Paragraph>Implementation and management of robust firewall and IDS solutions to monitor and protect network traffic.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Secure VPN and Remote Access</Title>
                                    <Paragraph>Secure VPN setups and remote access solutions to ensure safe and encrypted communication for remote workers.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Network Access Control (NAC)</Title>
                                    <Paragraph>Enforcement of policies and controls to manage and secure access to your network resources.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Threat Intelligence Integration</Title>
                                    <Paragraph>Integration of threat intelligence feeds to enhance threat detection and response capabilities.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Security Posture</Title>
                                    <Paragraph>Improved resilience against cyber threats and vulnerabilities with proactive security measures.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Operational Efficiency</Title>
                                    <Paragraph>Streamlined operations and reduced downtime with reliable and secure network infrastructure.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Regulatory Compliance</Title>
                                    <Paragraph>Adherence to regulatory requirements and industry standards with compliant network security practices.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Continuous Support</Title>
                                    <Paragraph>24/7 support and monitoring to respond to emerging threats and incidents promptly.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default NetworkSecurityPage;
