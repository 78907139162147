import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Layout } from 'antd';
import AppHeader from './components/Header/Header';
import AppFooter from './components/Footer/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Quote from './pages/Quote';
import SecurityMonitoring from './pages/SecurityMonitoring';
import VulnerabilityManagement from './pages/VulnerabilityManagement';
import PenetrationTesting from './pages/PenetrationTesting';
import ThreatDetectionandResponse from './pages/ThreatDetectionandResponse';
import IncidentResponseAndForensics from './pages/IncidentResponseAndForensics';
import ComplianceManagement from './pages/ComplianceManagement';
import AIEnabledCyberSecurity from './pages/AIEnabledCyberSecurity';
import WhyChooseVAPTIX from './pages/WhyChooseVAPTIX';
import CareersPage from './pages/CareersPage';
import NetworkSecurityPage from './pages/NetworkSecurityPage';
import CloudSecurityPage from './pages/CloudSecurityPage';
import EndpointProtectionPage from './pages/EndpointProtectionPage';
import ComplianceServicesPage from './pages/ComplianceServicesPage';
import DataAIPage from './pages/DataAIPage';
import RiskAnalysis from './pages/RiskAnalysis';
import Industries from './pages/IndustriesPage';
import './App.css';

const { Content } = Layout;

const App = () => {
    return (
        <Router>
            <Layout className="layout">
                <AppHeader />
                <Content style={{ padding: '0 0px'}}>
                    <div className="site-layout-content">
                        <Routes>
                            <Route path="/" element={<Home />} />
                            <Route path="/about" element={<About />} />
                            <Route path="/contact" element={<Contact />} />
                            <Route path="/quote" element={<Quote />} />
                            <Route path="/Industries" element={<Industries />} />
                            <Route path="/security-monitoring" element={<SecurityMonitoring/>} />
                            <Route path="/PenetrationTesting" element={<PenetrationTesting/>} />
                            <Route path="/vulnerability-management" element={<VulnerabilityManagement/>} />
                            <Route path="/ThreatDetectionandResponse" element={<ThreatDetectionandResponse/>} />
                            <Route path="/RiskAnalysis" element={<RiskAnalysis/>} />
                            <Route path="/IncidentResponseAndForensics" element={<IncidentResponseAndForensics/>} />
                            <Route path="/ComplianceManagement" element={<ComplianceManagement/>} />
                            <Route path="/AIEnabledCyberSecurity" element={<AIEnabledCyberSecurity/>} />
                            <Route path="/WhyChooseVAPTIX" element={<WhyChooseVAPTIX/>} />
                            <Route path="/CareersPage" element={<CareersPage/>} />
                            <Route path="/CloudSecurityPage" element={<CloudSecurityPage/>} />
                            <Route path="/NetworkSecurityPage" element={<NetworkSecurityPage/>} />
                            <Route path="/EndpointProtectionPage" element={<EndpointProtectionPage/>} />
                            <Route path="/ComplianceServicesPage" element={<ComplianceServicesPage/>} />
                            <Route path="/DataAIPage" element={<DataAIPage/>} />
                
                        </Routes>
                    </div>
                </Content>
                {/* <AppFooter /> */}
            </Layout>
        </Router>
    );
};

export default App;
