import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles;
const { Title, Paragraph } = Typography;

const CloudSecurityPage = () => {
    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Cloud Security Solutions</Title>
                <Paragraph className="hero-subtitle">Ensuring Secure and Resilient Cloud Environments</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Paragraph>
                            Protecting your data and applications in the cloud is crucial for maintaining business continuity and security. At [Your Company Name], we provide robust cloud security solutions tailored to safeguard your cloud environments against evolving cyber threats. Our comprehensive approach integrates advanced technologies and best practices to ensure your cloud infrastructure remains secure and compliant.
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Why Choose Our Cloud Security Solutions?</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Data Encryption</Title>
                                    <Paragraph>Ensure data confidentiality with robust encryption methods for data stored and transferred in the cloud.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Access Control</Title>
                                    <Paragraph>Implement granular access controls and identity management to restrict unauthorized access to cloud resources.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Threat Detection</Title>
                                    <Paragraph>Utilize advanced threat detection techniques to identify and respond to potential security incidents in real-time.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Compliance Management</Title>
                                    <Paragraph>Ensure adherence to regulatory requirements and industry standards with automated compliance management tools.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Key Features of Our Cloud Security Solutions</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Cloud Access Security Broker (CASB)</Title>
                                    <Paragraph>Implement CASB solutions to secure cloud services, manage shadow IT, and enforce data loss prevention policies.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Security Monitoring and Incident Response</Title>
                                    <Paragraph>Continuous monitoring of cloud environments for suspicious activities and rapid incident response to mitigate potential threats.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Multi-Factor Authentication (MFA)</Title>
                                    <Paragraph>Enhance authentication security with MFA to verify user identities and prevent unauthorized access to cloud applications and data.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Backup and Disaster Recovery</Title>
                                    <Paragraph>Implement reliable backup and disaster recovery solutions to ensure data resilience and business continuity in the cloud.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Scalability and Flexibility</Title>
                                    <Paragraph>Scale your cloud security solutions as your business grows and adapts to changing security requirements.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Cost Efficiency</Title>
                                    <Paragraph>Optimize costs with efficient cloud security measures and avoid potential financial losses due to security incidents.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Comprehensive Support</Title>
                                    <Paragraph>24/7 support and proactive monitoring to ensure continuous protection and rapid response to emerging threats.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Regulatory Compliance</Title>
                                    <Paragraph>Stay compliant with regulatory requirements and industry standards with our cloud security solutions.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default CloudSecurityPage;
