// src/components/Header/Logo.js
import React from 'react';
import { Link } from 'react-router-dom';

const Logo = () => (
  <div className="logo" style={{ float: 'left', color: 'white', fontSize: '24px' }}>
    <Link to="/">
    Vaptix
    </Link>
  </div>
);

export default Logo;
