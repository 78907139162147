import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles;
const { Title, Paragraph } = Typography;

const PenetrationTesting = () => {
    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Penetration Testing</Title>
                <Paragraph className="hero-subtitle">Strengthen Your Defenses Through Rigorous Testing</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Paragraph>
                            Penetration testing, also known as ethical hacking, is a critical step in evaluating your organization's security posture. At [Your Company Name], we offer comprehensive penetration testing services to identify vulnerabilities and strengthen your defenses against cyber threats.
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Why Choose Our Penetration Testing Services?</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Thorough Assessments</Title>
                                    <Paragraph>Comprehensive testing methodologies to uncover vulnerabilities across your systems and applications.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Real-World Scenarios</Title>
                                    <Paragraph>Simulate real-world attack scenarios to assess the effectiveness of your security controls.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Analysis</Title>
                                    <Paragraph>Detailed analysis and actionable recommendations by our certified penetration testing professionals.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Compliance Assurance</Title>
                                    <Paragraph>Ensure compliance with industry regulations and standards through our penetration testing services.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Key Features of Our Penetration Testing</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Network Penetration Testing</Title>
                                    <Paragraph>Identify vulnerabilities and security weaknesses in your network infrastructure.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Web Application Testing</Title>
                                    <Paragraph>Assess the security of your web applications and APIs through rigorous testing.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Mobile Application Testing</Title>
                                    <Paragraph>Evaluate the security of your mobile apps to prevent unauthorized access and data breaches.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Social Engineering Tests</Title>
                                    <Paragraph>Test your organization's susceptibility to social engineering attacks and phishing scams.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Benefits of Penetration Testing with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Security Posture</Title>
                                    <Paragraph>Identify and address vulnerabilities before malicious actors exploit them, enhancing your overall security posture.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Regulatory Compliance</Title>
                                    <Paragraph>Meet compliance requirements and industry standards through thorough penetration testing.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Cost-Effective Security</Title>
                                    <Paragraph>Prevent potential financial losses and reputation damage caused by security breaches with cost-effective testing solutions.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Guidance</Title>
                                    <Paragraph>Receive expert guidance and recommendations to improve your security defenses and incident response capabilities.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default PenetrationTesting;
