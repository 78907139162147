import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles;
const { Title, Paragraph } = Typography;

const EndpointProtectionPage = () => {
    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Endpoint Protection Solutions</Title>
                <Paragraph className="hero-subtitle">Securing Endpoints for Enhanced Security</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Paragraph>
                            Protecting endpoints such as desktops, laptops, and mobile devices is crucial to prevent security breaches and ensure data privacy. At [Your Company Name], we offer comprehensive endpoint protection solutions designed to safeguard your devices against cyber threats. Our advanced technologies and proactive approach provide robust security measures tailored to meet your organization's needs.
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Why Choose Our Endpoint Protection Solutions?</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Real-Time Threat Detection</Title>
                                    <Paragraph>Utilize advanced threat detection techniques to identify and respond to potential security incidents in real-time.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Behavioral Analysis</Title>
                                    <Paragraph>Monitor endpoint behavior patterns to detect and mitigate suspicious activities and malware infections.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Device Control</Title>
                                    <Paragraph>Implement granular device control policies to manage and secure endpoint devices accessing your network.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Data Loss Prevention</Title>
                                    <Paragraph>Prevent unauthorized data transfer and leakage with effective data loss prevention mechanisms on endpoints.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Key Features of Our Endpoint Protection Solutions</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Endpoint Detection and Response (EDR)</Title>
                                    <Paragraph>Deploy EDR solutions to detect, investigate, and respond to advanced threats targeting endpoint devices across your organization.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Application Control</Title>
                                    <Paragraph>Control and monitor applications running on endpoint devices to prevent unauthorized software installations and enforce security policies.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Patch Management</Title>
                                    <Paragraph>Automate patch deployment processes to ensure endpoint devices are up-to-date with the latest security patches and updates.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Remote Device Management</Title>
                                    <Paragraph>Manage and secure endpoint devices remotely, ensuring consistent security across all devices accessing your network.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Comprehensive Protection</Title>
                                    <Paragraph>Protect endpoint devices with multi-layered security measures and proactive threat detection capabilities.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Performance Optimization</Title>
                                    <Paragraph>Optimize endpoint device performance without compromising on security with efficient endpoint protection solutions.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Cost Efficiency</Title>
                                    <Paragraph>Reduce operational costs associated with endpoint security management through streamlined processes and automation.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Scalability</Title>
                                    <Paragraph>Scale endpoint protection solutions according to your organization's growth and evolving security needs.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default EndpointProtectionPage;
