import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles;
const { Title, Paragraph } = Typography;

const IncidentResponseAndForensics = () => {
    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Incident Response & Forensics</Title>
                <Paragraph className="hero-subtitle">Swift Resolution and Investigation of Security Incidents</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Paragraph>
                            In the face of security incidents, rapid response and thorough investigation are paramount. At [Your Company Name], we offer comprehensive incident response and forensic services to help you swiftly mitigate risks and minimize impact. Our expert team leverages advanced techniques and tools to ensure your organization can recover quickly and learn from security incidents.
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Why Choose Our Incident Response & Forensics Services?</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Swift Incident Response</Title>
                                    <Paragraph>Rapid and effective response to security incidents to minimize impact and prevent further damage.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Forensic Investigation</Title>
                                    <Paragraph>Thorough investigation using forensic techniques to identify the root cause and scope of security incidents.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Analysis</Title>
                                    <Paragraph>Expert analysis and reporting on findings to prevent future incidents and improve security posture.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Continuous Improvement</Title>
                                    <Paragraph>Recommendations for improving incident response procedures and security defenses based on insights gained.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Key Features of Our Services</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Incident Detection</Title>
                                    <Paragraph>Rapid detection of security incidents using advanced monitoring and alerting systems.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Response and Mitigation</Title>
                                    <Paragraph>Immediate response and mitigation actions to contain and remediate security incidents effectively.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Forensic Analysis</Title>
                                    <Paragraph>Forensic analysis to gather evidence, understand attack vectors, and support legal proceedings if necessary.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Incident Reporting</Title>
                                    <Paragraph>Detailed incident reports with actionable insights and recommendations for preventing future incidents.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Rapid Recovery</Title>
                                    <Paragraph>Minimize downtime and operational disruption with swift incident response and recovery procedures.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Security Posture</Title>
                                    <Paragraph>Improve your organization's security posture with proactive incident response and forensic analysis.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Compliance and Governance</Title>
                                    <Paragraph>Ensure compliance with regulatory requirements and industry standards through effective incident response practices.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Continuous Support</Title>
                                    <Paragraph>24/7 support and guidance from our expert team to handle incidents and improve response capabilities.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default IncidentResponseAndForensics;
