import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import { RightCircleTwoTone } from '@ant-design/icons';
import './ServiceStyle.css'; // Import styles for service pages
import './pagestyle.css'; // Import homepage styles;
const { Title, Paragraph } = Typography;

const ComplianceServicesPage = () => {
    return (
        <div className="service-page">
            <div className="hero-section">
                <Title level={1} className="hero-title">Compliance Services</Title>
                <Paragraph className="hero-subtitle">Ensuring Regulatory Compliance for Your Business</Paragraph>
            </div>

            <div className="content-section">
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Paragraph>
                            Achieving and maintaining compliance with regulatory standards is essential for businesses today. At [Your Company Name], we provide comprehensive compliance services tailored to meet the specific requirements of your industry. Our expertise in regulatory frameworks ensures that your business operations adhere to legal obligations, mitigating risks and enhancing trust among stakeholders.
                        </Paragraph>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Why Choose Our Compliance Services?</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Comprehensive Audits</Title>
                                    <Paragraph>Conduct thorough audits to assess your current compliance status and identify areas for improvement.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Regulatory Guidance</Title>
                                    <Paragraph>Provide expert guidance on navigating complex regulatory landscapes and implementing compliant practices.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Policy Development</Title>
                                    <Paragraph>Develop tailored policies and procedures to ensure alignment with regulatory requirements and best practices.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={4}><div className="feature-title-icon"><RightCircleTwoTone /></div>Compliance Training</Title>
                                    <Paragraph>Offer training programs to educate employees on compliance standards and foster a culture of compliance within your organization.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-even-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Key Features of Our Compliance Services</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Risk Assessment</Title>
                                    <Paragraph>Conduct comprehensive risk assessments to identify potential compliance risks and develop mitigation strategies.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Compliance Monitoring</Title>
                                    <Paragraph>Implement monitoring mechanisms to track compliance activities and ensure ongoing adherence to regulatory requirements.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Incident Response Planning</Title>
                                    <Paragraph>Develop incident response plans to address compliance breaches promptly and minimize their impact on your business.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={12}>
                                <Card className="feature-card" bordered={false}>
                                    <Title level={3}>Continuous Improvement</Title>
                                    <Paragraph>Facilitate continuous improvement initiatives to adapt to evolving regulatory requirements and enhance compliance practices.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row gutter={[32, 32]} className="service-odd-row">
                    <Col span={24}>
                        <Title level={2} className='topic-title'>Benefits of Partnering with Us</Title>
                        <Row gutter={[16, 16]}>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Enhanced Compliance</Title>
                                    <Paragraph>Ensure comprehensive compliance with regulatory standards, reducing the risk of penalties and legal liabilities.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Operational Efficiency</Title>
                                    <Paragraph>Streamline compliance processes and workflows to optimize operational efficiency and minimize compliance-related costs.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Business Continuity</Title>
                                    <Paragraph>Maintain business continuity by proactively addressing compliance risks and ensuring resilience against regulatory changes.</Paragraph>
                                </Card>
                            </Col>
                            <Col xs={24} sm={6}>
                                <Card hoverable className="feature-card" bordered={false}>
                                    <Title className="feature-title" level={3}><div className="feature-title-icon"><RightCircleTwoTone /></div>Expert Support</Title>
                                    <Paragraph>Access expert support and guidance to navigate compliance challenges effectively and achieve sustainable compliance.</Paragraph>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </div>
    );
};

export default ComplianceServicesPage;

